import { IOpeningDayInfo } from '../interfaces';
import { DbOrganizationResourceEntity } from './db.entity';
import { StoreGroupEntity } from './store-group.entity';

export class OpeningWeekCalendarEntity {
  [weekday: number]: IOpeningDayInfo;
}

export class StoreContactEntity {
  type?: string;
  description?: string;
  value?: string;
}

export class StoreEntity extends DbOrganizationResourceEntity<StoreEntity> {
  active: boolean;
  code: number;
  description: string;
  storeGroupId?: string;
  address?: string;
  city?: string;
  lat?: string;
  long?: string;

  /// DEPRECATED ///
  email?: string;
  /// DEPRECATED ///
  website?: string;
  /// DEPRECATED ///
  contact?: string;

  openingCalendar?: OpeningWeekCalendarEntity;
  contacts?: StoreContactEntity[];

  readonly storeGroup?: StoreGroupEntity;
}
