import { ErrorHandler, Injectable, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpResponse } from '@angular/common/http';
import {
  NbAuthJWTToken,
  NbAuthModule,
  NbPasswordAuthStrategy,
  NbPasswordAuthStrategyOptions,
  getDeepFromObject,
} from '@nebular/auth';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';
import { of as observableOf } from 'rxjs';

import { throwIfAlreadyLoaded } from './module-import-guard';

import {
  AccountService,
  AnalyticsService,
  BackendService,
  LayoutService,
  PlayerService,
  SeoService,
  StateService,
  UtilityService,
  AppUpdateService,
  GlobalErrorHandler,
} from './utils';

import { UserData } from './data/users';
import { ElectricityData } from './data/electricity';
import { SmartTableData } from './data/smart-table';
import { UserActivityData } from './data/user-activity';
import { OrdersChartData } from './data/orders-chart';
import { ProfitChartData } from './data/profit-chart';
import { TrafficListData } from './data/traffic-list';
import { EarningData } from './data/earning';
import { OrdersProfitChartData } from './data/orders-profit-chart';
import { TrafficBarData } from './data/traffic-bar';
import { ProfitBarAnimationChartData } from './data/profit-bar-animation-chart';
import { TemperatureHumidityData } from './data/temperature-humidity';
import { SolarData } from './data/solar';
import { TrafficChartData } from './data/traffic-chart';
import { StatsBarData } from './data/stats-bar';
import { CountryOrderData } from './data/country-order';
import { StatsProgressBarData } from './data/stats-progress-bar';
import { VisitorsAnalyticsData } from './data/visitors-analytics';
import { SecurityCamerasData } from './data/security-cameras';

import { UserService } from './mock/users.service';
import { ElectricityService } from './mock/electricity.service';
import { SmartTableService } from './mock/smart-table.service';
import { UserActivityService } from './mock/user-activity.service';
import { OrdersChartService } from './mock/orders-chart.service';
import { ProfitChartService } from './mock/profit-chart.service';
import { TrafficListService } from './mock/traffic-list.service';
import { EarningService } from './mock/earning.service';
import { OrdersProfitChartService } from './mock/orders-profit-chart.service';
import { TrafficBarService } from './mock/traffic-bar.service';
import { ProfitBarAnimationChartService } from './mock/profit-bar-animation-chart.service';
import { TemperatureHumidityService } from './mock/temperature-humidity.service';
import { SolarService } from './mock/solar.service';
import { TrafficChartService } from './mock/traffic-chart.service';
import { StatsBarService } from './mock/stats-bar.service';
import { CountryOrderService } from './mock/country-order.service';
import { StatsProgressBarService } from './mock/stats-progress-bar.service';
import { VisitorsAnalyticsService } from './mock/visitors-analytics.service';
import { SecurityCamerasService } from './mock/security-cameras.service';
import { MockDataModule } from './mock/mock-data.module';

import { environment } from '../../environments/environment';
import { AuthGuard, OrganizationGuard, CheckPermissionGuard, GuestGuard } from './auth-guard.service';
import { AuthInterceptor } from './http-interceptor';

const DATA_SERVICES = [
  { provide: UserData, useClass: UserService },
  { provide: ElectricityData, useClass: ElectricityService },
  { provide: SmartTableData, useClass: SmartTableService },
  { provide: UserActivityData, useClass: UserActivityService },
  { provide: OrdersChartData, useClass: OrdersChartService },
  { provide: ProfitChartData, useClass: ProfitChartService },
  { provide: TrafficListData, useClass: TrafficListService },
  { provide: EarningData, useClass: EarningService },
  { provide: OrdersProfitChartData, useClass: OrdersProfitChartService },
  { provide: TrafficBarData, useClass: TrafficBarService },
  { provide: ProfitBarAnimationChartData, useClass: ProfitBarAnimationChartService },
  { provide: TemperatureHumidityData, useClass: TemperatureHumidityService },
  { provide: SolarData, useClass: SolarService },
  { provide: TrafficChartData, useClass: TrafficChartService },
  { provide: StatsBarData, useClass: StatsBarService },
  { provide: CountryOrderData, useClass: CountryOrderService },
  { provide: StatsProgressBarData, useClass: StatsProgressBarService },
  { provide: VisitorsAnalyticsData, useClass: VisitorsAnalyticsService },
  { provide: SecurityCamerasData, useClass: SecurityCamerasService },
];

@Injectable()
export class NbSimpleRoleProvider implements NbRoleProvider {
  constructor(private account: AccountService) { }
  getRole() {
    const user = this.account.getUser();
    return observableOf(user ? user.role : 'guest');
  }
}

export const NB_CORE_PROVIDERS = [
  ...MockDataModule.forRoot().providers,
  ...DATA_SERVICES,
  ...NbAuthModule.forRoot({
    strategies: [
      NbPasswordAuthStrategy.setup({
        name: 'email',
        baseEndpoint: environment.apiUrl,
        login: {
          endpoint: '/auth/login',
          method: 'post',
          redirect: {
            success: '/account/check',
          },
          defaultErrors: [
            "Qualcosa è andato storto durante l'accesso. Riprovare e se il problema persiste contattare l'assistenza",
          ],
          defaultMessages: ['Benvenuto su Loyx Platform'],
        },
        requestPass: {
          endpoint: '/auth/request-password-reset',
          method: 'post',
          defaultErrors: [
            "Qualcosa è andato storto durante la richiesta. Riprovare e se il problema persiste contattare l'assistenza",
          ],
          defaultMessages: [
            'Abbiamo ricevuto la tua richiesta. Se la tua email è corretta riceverai le istruzioni per impostare una nuova password',
          ],
        },
        // register: {
        //   endpoint: '/auth/register',
        //   method: 'post',
        // },
        token: {
          class: NbAuthJWTToken,
          key: 'token',
        },
        messages: {
          key: 'body.message',
          getter: (module: string, res: HttpResponse<Object>, options: NbPasswordAuthStrategyOptions) =>
            getDeepFromObject(res, options.messages.key, options[module].defaultMessages),
        },
        errors: {
          key: 'error.message',
          getter: (module: string, res: HttpResponse<Object>, options: NbPasswordAuthStrategyOptions) =>
            getDeepFromObject(res, options.errors.key, options[module].defaultErrors),
        },
      }),
    ],
    forms: {
      login: {
        redirectDelay: 0, // delay before redirect after a successful login, while success message is shown to the user
        strategy: 'email', // strategy id key.
        rememberMe: true, // whether to show or not the `rememberMe` checkbox
        showMessages: {
          // show/not show success/error messages
          success: false,
          error: true,
        },
      },
      register: {
        // socialLinks: [],
      },
      requestPassword: {
        redirectDelay: 15000,
      },
      validation: {
        password: {
          minLength: 8,
          maxLength: 24,
        },
      },
    },
  }).providers,
  NbSecurityModule.forRoot({
    accessControl: {
      store_clerk: {
        view: [
          'org_loyaltycards',
          'org_loyaltycards_transactions',
          'org_campaigns',
          'org_giftcards',
          'org_orders',
          'org_rewards',
          'org_giftcards_transactions',
        ],
        create: ['org_loyaltycards'],
        update: ['org_loyaltycards', 'org_orders'],
      },
      store_manager: {
        parent: 'store_clerk',
        view: ['org_accounts'],
        create: ['org_orders'],
        update: [],
      },
      store_owner: {
        parent: 'store_manager',
        view: [],
        create: [
          'org_giftcards',
          'org_giftcards_transactions',
          'org_accounts',
          'org_loyaltycards_transactions',
        ],
        update: [
          'org_giftcards',
          'org_giftcards_transactions',
          'org_accounts',
          'org_loyaltycards_transactions',
        ],
      },
      organization_manager: {
        parent: 'store_owner',
        view: [
          'org_settings',
          'org_users',
          'org_logs',
          'org_cards_type',
          'org_news',
          'org_flyers',
          'org_medias',
          'org_stores',
        ],
        create: [
          'org_stores',
          'org_campaigns',
          'org_news',
          'org_rewards',
          'org_settings',
          'org_users',
          'org_medias',
          'org_flyers',
        ],
        update: [
          'org_stores',
          'org_campaigns',
          'org_news',
          'org_rewards',
          'org_settings',
          'org_users',
          'org_medias',
          'org_flyers',
        ],
        delete: [
          'org_accounts',
          'org_loyaltycards',
          'org_stores',
          'org_campaigns',
          'org_giftcards',
          'org_news',
          'org_orders',
          'org_rewards',
          'org_giftcards_transactions',
          'org_loyaltycards_transactions',
          'org_settings',
          'org_users',
          'org_medias',
          'org_flyers',
        ],
      },
      help_desk: {
        view: '*',
        create: [
          'organizations',
          'org_users',
          'admin_users',
          'org_stores',
          'org_loyaltycards',
          'org_accounts',
          'org_rewards',
          'org_orders',
          'org_campaigns',
          'org_giftcards',
          'org_news',
          'org_settings',
          'org_cards_type',
          'org_giftcards_transactions',
          'org_loyaltycards_transactions',
          'org_logs',
          'org_giftcard_code',
          'org_medias',
          'org_flyers',
        ],
        update: [
          'organizations',
          'org_users',
          'org_stores',
          'org_loyaltycards',
          'org_accounts',
          'org_rewards',
          'org_orders',
          'org_campaigns',
          'org_giftcards',
          'org_news',
          'org_settings',
          'org_cards_type',
          'org_giftcards_transactions',
          'org_loyaltycards_transactions',
          'org_giftcard_unlock',
          'org_medias',
          'org_flyers',
        ],
        delete: [
          'org_users',
          'org_stores',
          'org_loyaltycards',
          'org_accounts',
          'org_rewards',
          'org_orders',
          'org_campaigns',
          'org_giftcards',
          'org_news',
          'org_settings',
          'org_cards_type',
          'org_giftcards_transactions',
          'org_loyaltycards_transactions',
          'org_cards_type',
          'org_medias',
          'org_flyers',
        ],
      },
      super_admin: {
        view: '*',
        create: '*',
        update: '*',
        delete: '*',
      },
    },
  }).providers,
  {
    provide: NbRoleProvider,
    useClass: NbSimpleRoleProvider,
  },
  AnalyticsService,
  LayoutService,
  PlayerService,
  SeoService,
  StateService,
  AuthGuard,
  GuestGuard,
  OrganizationGuard,
  CheckPermissionGuard,
  BackendService,
  AccountService,
  UtilityService,
  AppUpdateService,
  { provide: ErrorHandler, useClass: GlobalErrorHandler },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];

@NgModule({
  imports: [CommonModule],
  exports: [NbAuthModule],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [...NB_CORE_PROVIDERS],
    };
  }
}
